import * as React from 'react';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LensIcon from '@mui/icons-material/Lens';
import Typography from '@mui/material/Typography';

import '../css/Layout.css';

function Educations() {
    return (
        <div>
            <Container sx={{ mt: 25, mb: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', }} className='education-div'>
                <Grid container spacing={2}>
                    <Grid item md={1}>
                    </Grid>
                    <Grid item md={3}>
                    </Grid>
                    <Grid item md={6}>
                        <Chip label="Educations" />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ mt: 5, mb: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Timeline className='time-line'>
                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2012-2016
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Bachelor's Degree
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                Institute of Engineering - Kathmandu,Nepal
                            </Typography>
                            <br />
                            <Typography>Electronics and Communication Engineering (Full Scholarship),
                                First Division(71.2%)
                            </Typography>

                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2010-2012
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                High School(Collage)
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                Emereld Academy, Jhapa, Nepal
                            </Typography>
                            <br />
                            <Typography>Science with a major in Physics, Chemistry, and Mathematics,
                                First Division with a percentage of 66.7%.
                            </Typography>

                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                           2009
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Government of Nepal(School)
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                Gyan Jhoti Sec School,Jhapa,Nepal
                            </Typography>
                            <br />
                            <Typography>
                                Major in Math and Science, First Division with a percentage of 76.1%
                            </Typography>

                        </TimelineContent>
                    </TimelineItem>

                </Timeline>
            </Container>
        </div>

    );
}

export default Educations;