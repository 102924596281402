import * as React from 'react';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import avatarImg from '../img/avatar.jpeg';
import '../css/Layout.css';

function Aboutme() {
    return (
        <Container sx={{ mt: 25, mb: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', }} className='about-me'>
            <Grid container spacing={2}>
                <Grid item md={1}>
                </Grid>
                <Grid item md={3}>
                    <img src={avatarImg} alt="increase priority" className='aboutmeImg' />
                </Grid>
                <Grid item md={8}>
                    <Chip label="About me" />
                    <p className='header-title'>
                        Hello,I'm Samir Dahal, a Creative / Full Stack Developer based in London, England,
                        with 8 years of valuable working experience. Specializing in the Microsoft stack,
                        I've cultivated expertise in technologies like ASP.NET, C#, and Azure.
                        I prioritize clean and readable code and have actively contributed to projects using
                        the Microsoft stack, ensuring robust and innovative solutions.
                        My dedication to user-centric design aligns seamlessly with the dynamic UX/UI landscape.
                        Beyond coding, I find balance by pursuing sports and occasionally indulging my geeky interests,
                        rounding out my multifaceted approach to work and life.
                    </p>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Aboutme;