import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import FacebookIcon from '@mui/icons-material/Facebook';
import Link from '@mui/material/Link';


import Grid from '@mui/material/Grid';
import '../css/Layout.css';

function Footer() {
    return (
        <Container sx={{ mt: 0, mb: 0, alignItems: 'center', }} className='footer'>
            <Grid container spacing={2}>
                <Grid item md={3}>
                </Grid>
                <Grid item md={6} className='main-footer'>
                    <Typography variant="h6" className='footer-text'>
                        Don't forget to follow me..
                    </Typography>

                    <BottomNavigation className='link-footer'>
                        <Link href="https://www.linkedin.com/in/samir-dahal-44b763167/" target="_blank"><BottomNavigationAction label="Recents" icon={<LinkedInIcon />} className='social-linked' /></Link>
                        <Link href="https://github.com/samirdahal069" target="_blank"><BottomNavigationAction label="Recents" icon={<GitHubIcon />} className='social-linked' /></Link>
                        <Link href="https://www.facebook.com/samir.dahal.900" target="_blank"><BottomNavigationAction label="Recents" icon={<FacebookIcon />} className='social-linked' /></Link>
                    </BottomNavigation>
                    <br>
                    </br>
                    <Typography variant="h6" className='footer-text'>
                    samir dahal © | all rights reserved
                    </Typography>
                </Grid>
                <Grid item md={3}>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Footer;