import * as React from 'react';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import '../css/Layout.css';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

function Abilities() {
    return (
        <Container sx={{ mt: 25, mb: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

            <Grid container spacing={2}>
                <Grid item md={1}>
                </Grid>
                <Grid item md={3}>
                </Grid>
                <Grid item md={6}>
                    <Chip label="Abilities" />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <p className='header-title'>
                        As a Creative Web Developer with expertise in the Microsoft stack,
                        I leverage technologies such as ASP.NET, C#, and Azure for robust backend development.
                        My proficiency extends to crafting intuitive user interfaces using modern UI frameworks,
                        such as Xamarin.Forms for cross-platform mobile apps or ASP.NET Core MVC for web applications.
                        I ensure seamless integration of backend and frontend components, providing users with a visually
                        appealing and responsive experience within the Microsoft ecosystem.
                    </p>
                </Grid>
            </Grid>


            <Grid container spacing={2}>
                <Grid item md={6}>
                    <Typography variant="h6" component="span" className='position-title'>
                        Coding
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="C" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="C++" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="C#" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="ASP.NET(Core, Web Form,Blazor,Razor,MVC,Entity Framework)" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="VB.NET" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="SQL (MSSQL,MySQL)" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Xamarin" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="JavaScript/TypeScript(React, Angular)" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Node Js, Express JS" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="HTML" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="CSS" />
                            </ListItemButton>
                        </ListItem>
                    </List>

                    <Typography variant="h6" component="span" className='position-title'>
                        Operating System
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="Windows/Linux/Mac" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Android/IOS" />
                            </ListItemButton>
                        </ListItem>
                    </List>

                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" component="span" className='position-title'>
                        Cloud
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="Azure(Azure Function,Logic Apps,CosmosDB,Blobs,Networking)" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="AWS(EC2,Lamda,S3,RDS,DynamoDB)" />
                            </ListItemButton>
                        </ListItem>
                    </List>


                    <Typography variant="h6" component="span" className='position-title'>
                        Tools / Others
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="Visual Studio" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Visual Studio Code" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="Git(Git bash,Git hub, Bitbucket,Source Tree,SVN)" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Docker" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="Swagger,OpenAPI" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="Postman" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                    <Typography variant="h6" component="span" className='position-title'>
                        Languages
                    </Typography>
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="English" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton component="a" href="#simple-list">
                                <ListItemText primary="Nepali" />
                            </ListItemButton>
                        </ListItem>

                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemText primary="Hindi" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Abilities;