import * as React from 'react';
import Typography from '../components/Typography';

import Container from '@mui/material/Container';
import { styled, createTheme } from '@mui/material/styles';
import layoutImg from '../img/layoutImg.jpg';
import '../css/Layout.css';



const Background = styled('div')({
    backgroundImage: `url(${layoutImg})`,
    backgroundPosition: 'center',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    filter: 'blur(3px)',
    zIndex: -1,
});

const LayoutRoot = styled('section')(({ theme }) => ({
    color: theme.palette.common.white,
    backgroundColor: 'rgba(158, 46, 46, 0.5)',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.up('sm')]: {
        height: '100vh',
        minHeight: 500,
        maxHeight: 1300,
    },
}));

const theme = createTheme({
    typography: {
        fontFamily: 'Permanent Marker, Work Sans, sans-serif',
    },
});


function Layout() {
    return (
        <LayoutRoot>
            <Container sx={{ mt: 3, mb: 14, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Background />
                <img style={{ display: 'none' }} src={layoutImg} alt="increase priority" />
                <Typography className='nameText' theme={theme} variant='h2' align='center'>
                    Hello! I'm Samir Dahal
                </Typography>
                <Typography className='subText' theme={theme} variant='h4' align='center' marked="center">
                    Full Stack Developer
                </Typography>
            </Container>
        </LayoutRoot>

    );
}

export default Layout;