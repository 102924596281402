import * as React from 'react';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LensIcon from '@mui/icons-material/Lens';
import Typography from '@mui/material/Typography';

import '../css/Layout.css';

function Careers() {
    return (
        <div>
            <Container sx={{ mt: 25, mb: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', }} className='careers-div'>
                <Grid container spacing={2}>
                    <Grid item md={1}>
                    </Grid>
                    <Grid item md={3}>
                    </Grid>
                    <Grid item md={6}>
                        <Chip label="Careers" />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ mt: 5, mb: 5, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Timeline className='time-line'>
                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2021 - Current
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Lead Developer @ Geeks
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                UK,London,Sutton | <Link href="https://www.geeks.ltd.uk" target="_blank">Link</Link>
                            </Typography>
                            <br />
                            <Typography>
                                ● Engaging in a diverse array of client projects encompassing Channel Ports, Search Acumen, Genesis
                                Capital, PJP Cellular Pathology Services, and Houton Cox.
                                <br />
                                ● Worked closely with other team members, such as designers, product managers,business analyst,solution
                                designer and testers, to contribute to the complete software development lifecycle.
                                <br />
                                ● Collaborating with architects and other senior team members to design software systems and components.
                                Make decisions regarding the overall structure, architecture, and technology stack of the project.
                                <br />
                                ● Providing expert-level guidance on architecture, design patterns, and best practices to guide the development
                                team.
                                <br />
                                ● Mentoring and coaching mid-level and junior developers, fostering their growth and professional
                                development.
                                <br />
                                ● Setting and enforcing coding standards and best practices throughout the development process.
                                <br />
                                ● Conducting thorough code reviews, offering constructive feedback to maintain code quality and consistency.
                                <br />
                                ● Working closely with clients to understand their needs, provide technical insights, and address any concerns
                                or questions.
                                <br />
                                ● Optimizing application performance through in-depth analysis, profiling, and implementing advanced
                                optimizations.
                                <br />
                            </Typography>

                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2020 – 2021
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Senior Software Engineer I | @ Vesuvio Labs(Fulltime)
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                UK(Nepal,Kathmandu Branch ) | <Link href="https://www.vesuvio.io/" target="_blank">Link</Link>
                            </Typography>
                            <br />
                            <Typography>
                                ● Directed software design and development across a 25–30-member development team while remaining focused on
                                meeting client needs for functionality, timeline and performance.
                                <br />
                                ● Designed and developed APIs and integrations with external systems, adhering to best practices and security
                                considerations.
                                <br />
                                ● Used version control systems like Git to manage and track changes to the codebase.
                                <br />
                                ● Adapted to new tools, frameworks, and technologies as required by the project or the organization's tech stack.
                            </Typography>

                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2019 – 2020
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Software Engineer II | @ Consoltica ltd
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                USA(Nepal,Kathmandu Branch ) | <Link href="https://www.consoltica.com" target="_blank">Link</Link>
                            </Typography>
                            <br />
                            <Typography>
                                ● Designed Multi Tenant SaaS Software using the .NET framework.
                                <br />
                                ● Contributed to the architecture and design discussions, providing valuable insights based on experience.
                                <br />
                                ● Analyzed and solved challenging technical problems, debugging complex issues and providing effective solutions.
                            </Typography>

                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2017 – 2019
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Software Engineer I | @ Bitspanda Technology
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                Nepal,Kathmandu | <Link href="http://bitspanda.com/" target="_blank">Link</Link>
                            </Typography>
                            <br />
                            <Typography>
                                ● Written code in C# and .NET languages to implement software features and functionalities.
                                <br />
                                ● Developed and maintained web applications, desktop applications using the .NET Core.
                                <br />
                                ● Continuously learned and improved coding skills by staying updated with the latest .NET technologies and best
                                practices.
                                <br />
                            </Typography>

                        </TimelineContent>
                    </TimelineItem>


                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2016 – 2017
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Junior Software Engineer | @ Search Technology
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                Nepal,Kathmandu | <Link href="https://searchtech.com.np/" target="_blank">Link</Link>
                            </Typography>
                            <br />
                            <Typography>
                                ● Written code in C# or other .NET languages to implement software features and functionalities.
                                <br />
                                ● Collaborated with senior developers to understand software requirements and design specifications.
                                <br />
                                ● Identified and addressed software defects and bugs through debugging and testing.
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                </Timeline>
            </Container>
        </div>

    );
}

export default Careers;