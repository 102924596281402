import * as React from 'react';
import Container from '@mui/material/Container';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LensIcon from '@mui/icons-material/Lens';
import Typography from '@mui/material/Typography';

import '../css/Layout.css';

function Certification() {
    return (
        <div>
            <Container sx={{ mt: 25, mb: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', }} className='education-div'>
                <Grid container spacing={2}>
                    <Grid item md={1}>
                    </Grid>
                    <Grid item md={3}>
                    </Grid>
                    <Grid item md={6}>
                        <Chip label="Certification" />
                    </Grid>
                </Grid>
            </Container>

            <Container sx={{ mt: 5, mb: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                <Timeline className='time-line'>
                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2023-2026
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                AWS Certified Cloud Practitioner
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                <Link href="https://www.credly.com/badges/a1b78124-76ae-4f74-b685-93fa8fd15d40/linked_in_profile" target="_blank">View</Link>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>

                    <TimelineItem>
                        <TimelineOppositeContent align="right">
                            2023-
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                            <TimelineDot>
                                <LensIcon />
                            </TimelineDot>
                            <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent sx={{ py: '12px', px: 2 }}>
                            <Typography variant="h6" component="span" className='position-title'>
                                Microsoft Certified Azure Fundamental
                            </Typography>
                            <br />
                            <Typography className='company-name'>
                                <Link href="https://learn.microsoft.com/en-gb/users/samirdahal-9345/credentials/8524a8c3ffada48" target="_blank">View</Link>
                            </Typography>
                        </TimelineContent>
                    </TimelineItem>
                </Timeline>
            </Container>
        </div>
    );
}

export default Certification;