import Abilities from './pages/Abilities';
import Aboutme from './pages/Aboutme';
import Careers from './pages/Careers';
import Certification from './pages/Certification';
import Educations from './pages/Educations';
import Footer from './pages/Footer';
import Layout from './pages/Layout';
import Projects from './pages/Projects';

function App() {
  return (
    <div>
      <Layout />
      <Aboutme />
      <Careers />
      <Educations />
      <Certification />
      <Abilities />
      <Projects />
      <Footer />
    </div>
  );
}

export default App;
