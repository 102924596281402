import * as React from 'react';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import '../css/Layout.css';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { Link } from '@mui/material';

function Projects() {
    return (
        <Container sx={{ mt: 25, mb: 25, display: 'flex', flexDirection: 'column', alignItems: 'center', }}>

            <Grid container spacing={2}>
                <Grid item md={1}>
                </Grid>
                <Grid item md={3}>
                </Grid>
                <Grid item md={6}>
                    <Chip label="Projects" />
                </Grid>
            </Grid>
            <Grid container spacing={2} className='header-project'>
                <Grid item md={12}>
                    <p className='header-title'>These projects not only highlight my technical proficiency
                        but also underscore my commitment to producing impactful and high-quality results.
                        I find immense satisfaction in the challenges they presented and the innovative solutions
                        I contributed.
                        They serve as enduring milestones in my professional journey.
                    </p>
                </Grid>
            </Grid>

            <Grid container spacing={2} className='project-container'>
                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Channel Ports
                                </Typography>
                                <Typography sx={{ fontSize: 14 }} >
                                    C#,Dot Net Core(MVC),Jquery,Type Scripts,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    Portal: Shipment management system with HMRC interation into and out of UK
                                    <br />
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://customspro.net" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>
                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Search acumen
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Dot Net Core(MVC),Jquery,Type Scripts,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    Transforming the property industry through digitised data.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://www.search-acumen.co.uk" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>

                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    PJPPath - Client Portal(CPS)
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Web Form .Net,Jquery,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    Clients Portal: Cellular Pathology Services has been providing comprehensive and customised histopathology and cytopathology services to the independent and NHS
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://pjppath.com/login?ReturnUrl=/login" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>

                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Genesis Capital
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Web Form .Net,Jquery,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    UK's leading independent finance providers : helping users acquire and
                                    vendors sell business software and IT hardware
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://gcapdirect.com/login?ReturnUrl=/login" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>

                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Daly product
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    Saas Multi-tenant,C#,Web Form .Net,Jquery,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    DALY Computers’ Customer Web Portal
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://pwcs.dalyportal.com/" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>
                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Mero Pet
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Dot Net Core(MVC),Jquery,Type Scripts,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    An Advanced Concept In Absolute Pet Care with E-Commerce
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="http://meropet.com" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>


                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Mero yatra(Egtkt)
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Dot Net Core(MVC),Jquery,Type Scripts,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    Online bus ticket portal with payments and find a good deals.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://meroyatra.com.np" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>

                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Sagarmatha digital
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Dot Net Core(MVC),Jquery,Type Scripts,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    Delivering tunes that always touches the heart and soul from the extraordinary makers
                                    of Nepali music scenario to the audience with good taste of music.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://sagarmathadigital.com" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>



                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Bits Flock
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Dot Net Core(MVC),Jquery,Type Scripts,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    Bitsflock is an integrated poultry data analytics software which is fast, simple and
                                    affordable integrated solution for farm to feeder company.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="http://bitsflock.com" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>

                <Box sx={{ padding: 0.5, maxWidth: 273 }} md={3}>
                    <Card variant="outlined">
                        <Grid >
                            <CardContent>
                                <Typography variant="h5" component="div" color="text.secondary" gutterBottom>
                                    Vishwa news
                                </Typography>
                                <Typography sx={{ fontSize: 14 }}>
                                    C#,Web Form .Net,Jquery,Type Scripts,MSSQL
                                </Typography>
                                <br />
                                <Typography variant="body2">
                                    News Portal website, Epaper
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Link size="small" href="https://vishwanews.com" target="_blank">Learn More</Link>
                            </CardActions>
                        </Grid>
                    </Card>
                </Box>
            </Grid>
        </Container>
    );
}

export default Projects;